/**
 * This script is responsible for updating the state of loader element that is added to every page
 * via the default template.
 *
 * You should import it as the first line of code in a new webpack bundle, and call `finalizePageLoad`
 * as the last line.
 */

const el = document.getElementById("global-initial-loader");
if (el) {
  el.innerText = "Loading Mission Control JS...";
}

export function finalizePageLoad() {
  if (el) {
    el.parentNode.removeChild(el);
  }
}
