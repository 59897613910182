import Raven from "raven-js";

export function init() {
  if (window && window.Clover && window.Clover.ravenUrl) {
    const options = {
      release: window.Clover.release,
      environment: window.Clover.environment,
      captureUnhandledRejections: true
    };
    Raven.config(window.Clover.ravenUrl, options).install();

    const user = window.Clover.currentUser;
    if (user) {
      Raven.setUserContext({
        email: user.email,
        id: user.clover_staff_id
      });
    }
  }
}
