/* eslint-disable import/first */
import { finalizePageLoad } from "mission_control/global-loader";

import "inline_javascript/app.less";

import { init as initSentry } from "mission_control/sentry";

initSentry();

// eslint-disable-next-line import/no-webpack-loader-syntax
import "expose-loader?jQuery!expose-loader?$!jquery";
import "bootstrap";

finalizePageLoad();
